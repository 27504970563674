<template>
  <div class="about ">
    <section class=" bg-light" >
        <div class="container">
            <div class="row justify-content-center">
              <div class="col-xl-8 col-lg-9 col-md-11">
              <h2 v-if="page==='technology'">Payment For <span class="redtext">Mullan Technology</span></h2>
              <h2 v-if="page==='training'">Payment For <span class="bluetext">Mullan Training</span></h2>
              <h2 v-if="page==='property'">Payment For <span class="greentext">Mullan Property</span></h2>
              

                <div class="row ">
                  <div class="col-md-6" v-if="paymentDetails.name != ''">
                    <h4>{{ paymentDetails.name }}</h4>
                  </div>
                  <div class="col-md-6 text-right" v-if="paymentDetails.invoice_number != ''">
                    <h4>Invoice Number : {{ paymentDetails.invoice_number }}</h4>
                  </div>
                </div>

                <div class="card card-body shadow mt-0 " id="apply-form" v-if="showPaymentForm===true">
                  
                 
                  
                  <form id="payment-form"  @submit.prevent="submitPayment" class="example example2" v-if="paymentSuccessful===0" >
                       <p>Thank you for submitting your details. <br />Please enter your card details below to make payment</p>
                    <div id="card-element">
                    <!-- Elements will create input elements here -->
                    </div>

                    <!-- We'll put the error messages in this element -->
                    <div id="card-errors" role="alert"></div>

                    <button id="submit" type="submit" :disabled="pending===true">{{ buttonText }}</button>
                  </form>

                  <div class="alert alert-primary" role="alert" v-if="paymentSuccessful===1">
                        Your payment has been successful. Thank you.
                    </div>
                </div>

                <div class="alert alert-danger" role="alert"  v-if="showPaymentForm!=true" @click="backToPayment()">
                  A problem has occurred. Please click here to go back and check your details.
                </div>
              </div>
            </div>
        </div>
		</section>
  </div>
</template>

<script>
import axios from "axios"

let API_NAME = process.env.VUE_APP_API_NAME;
      
export default {
  props: ['paymentDetails', 'total_amount', 'stripeAPIToken', 'page'],
  data() {
    return {
      stripe: '',
        elements: '',
        card: '',
         intentToken: '',
         
          paymentSuccessful : 0,
          addPaymentStatusError: '',
          pending:false,
          buttonText: 'Pay £' + this.total_amount,
    }
  },
  methods: {
    backToPayment(){
      this.$emit('showPaymentForm')
    },
    configureStripe(){
        
        
        this.stripe = window.Stripe( this.stripeAPIToken );

        this.elements = this.stripe.elements();
        this.card = this.elements.create('card');

        this.card.mount('#card-element');
    },
    includeStripe( URL, callback )
    {
        let documentTag = document, tag = 'script',
            object = documentTag.createElement(tag),
            scriptTag = documentTag.getElementsByTagName(tag)[0];
        object.src = '//' + URL;
        if (callback) { object.addEventListener('load', function (e) { callback(null, e); }, false); }
          scriptTag.parentNode.insertBefore(object, scriptTag);
    },
    loadIntent(){
      var form_data = new FormData();
    form_data.append('total_amount', this.total_amount);
      for ( var key in this.paymentDetails ) {
          form_data.append(key, this.paymentDetails[key]);
      }
      axios.post(API_NAME + '/stripe.php', form_data)
         
        .then( function( response ){
            this.intentToken = response.data;
        }.bind(this))
        .catch((error) => {
            // Set this to hide the payment form
            this.total_amount = 0;
            alert("There was a problem with our payment processor. Please go back and try again - " + error);
         });
    },
    submitPayment(){
        this.paymentSuccessful = 0;
        this.pending = true;
        this.buttonText = "Processing";

        this.stripe.confirmCardPayment(
            this.intentToken.client_secret, {
                payment_method: {
                    card: this.card,
                    billing_details: {
                        name: this.paymentDetails.name,
                        email:this.paymentDetails.email
                    }
                }
            }
        ).then(function(result) {
            if (result.error) {
                this.paymentSuccessful = 0;
                this.pending = false;
                this.buttonText = 'Pay £' + this.total_amount,
                this.addPaymentStatusError = result.error.message;
                alert("There was a problem with your payment, please check all the fields and try again");
            } else {
                this.paymentSuccessful = 1;
                this.card.clear();
               
                //alert("Payment successful")
            }
        }.bind(this));
    },
  },
  mounted(){
      if(this.showPaymentForm == true)
      {
        this.includeStripe('js.stripe.com/v3/', function(){
              this.configureStripe();
          }.bind(this) );

          this.loadIntent();
      }
      else
      {
        //alert("There was a problem with your payment amount. Please go back and check your details.");
      }
      
     
  },
  computed : {
    showPaymentForm: function() {
      return this.total_amount != undefined && this.total_amount > 0.50
    },
    
  }
}
</script>

<style >

.example.example2 {
  background-color: #fff;
}

.example.example2 * {
 
  font-size: 16px;
  font-weight: 500;
}

.example.example2 .row {
  display: -ms-flexbox;
  display: flex;
  margin: 0 5px 10px;
}

.example.example2 .field {
  position: relative;
  width: 100%;
  height: 50px;
  margin: 0 10px;
}

.example.example2 .field.half-width {
  width: 50%;
}

.example.example2 .field.quarter-width {
  width: calc(25% - 10px);
}

.example.example2 .baseline {
  position: absolute;
  width: 100%;
  height: 1px;
  left: 0;
  bottom: 0;
  background-color: #cfd7df;
  transition: background-color 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.example.example2 label {
  position: absolute;
  width: 100%;
  left: 0;
  bottom: 8px;
  color: #cfd7df;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  transform-origin: 0 50%;
  cursor: text;
  pointer-events: none;
  transition-property: color, transform;
  transition-duration: 0.3s;
  transition-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
}

.example.example2 .input {
  position: absolute;
  width: 100%;
  left: 0;
  bottom: 0;
  padding-bottom: 7px;
  color: #32325d;
  background-color: transparent;
}

.example.example2 .input::-webkit-input-placeholder {
  color: transparent;
  transition: color 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.example.example2 .input::-moz-placeholder {
  color: transparent;
  transition: color 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.example.example2 .input:-ms-input-placeholder {
  color: transparent;
  transition: color 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.example.example2 .input.StripeElement {
  opacity: 0;
  transition: opacity 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
  will-change: opacity;
}

.example.example2 .input.focused,
.example.example2 .input:not(.empty) {
  opacity: 1;
}

.example.example2 .input.focused::-webkit-input-placeholder,
.example.example2 .input:not(.empty)::-webkit-input-placeholder {
  color: #cfd7df;
}

.example.example2 .input.focused::-moz-placeholder,
.example.example2 .input:not(.empty)::-moz-placeholder {
  color: #cfd7df;
}

.example.example2 .input.focused:-ms-input-placeholder,
.example.example2 .input:not(.empty):-ms-input-placeholder {
  color: #cfd7df;
}

.example.example2 .input.focused + label,
.example.example2 .input:not(.empty) + label {
  color: #aab7c4;
  transform: scale(0.85) translateY(-25px);
  cursor: default;
}

.example.example2 .input.focused + label {
  color: #24b47e;
}

.example.example2 .input.invalid + label {
  color: #ffa27b;
}

.example.example2 .input.focused + label + .baseline {
  background-color: #24b47e;
}

.example.example2 .input.focused.invalid + label + .baseline {
  background-color: #e25950;
}

.example.example2 input, .example.example2 button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  outline: none;
  border-style: none;
}

.example.example2 input:-webkit-autofill {
  -webkit-text-fill-color: #e39f48;
  transition: background-color 100000000s;
  -webkit-animation: 1ms void-animation-out;
}

.example.example2 .StripeElement--webkit-autofill {
  background: transparent !important;
}

.example.example2 input, .example.example2 button {
  -webkit-animation: 1ms void-animation-out;
}

.example.example2 button {
  display: block;
  width: calc(100% - 30px);
  height: 40px;
  margin: 40px 15px 0;
  background-color: #24b47e;
  border-radius: 4px;
  color: #fff;
  text-transform: uppercase;
  font-weight: 600;
  cursor: pointer;
}

.example.example2 .error svg {
  margin-top: 0 !important;
}

.example.example2 .error svg .base {
  fill: #e25950;
}

.example.example2 .error svg .glyph {
  fill: #fff;
}

.example.example2 .error .message {
  color: #e25950;
}

.example.example2 .success .icon .border {
  stroke: #abe9d2;
}

.example.example2 .success .icon .checkmark {
  stroke: #24b47e;
}

.example.example2 .success .title {
  color: #32325d;
  font-size: 16px !important;
}

.example.example2 .success .message {
  color: #8898aa;
  font-size: 13px !important;
}

.example.example2 .success .reset path {
  fill: #24b47e;
}

.alert-danger {cursor:pointer}

.redtext {color:#cc0000}
.bluetext {color:#000099}
.greentext {color:#009900}


</style>
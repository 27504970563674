<template>
    <div>
      <section class=" bg-light" v-if="showPayment===0">
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-xl-8 col-lg-9 col-md-11">
                        <h2>Make a Mullan Property Payment Online</h2>
                        <div class="card card-body shadow mt-md-5" id="apply-form">
                            <form action="#" @submit.prevent="checkForm()">
                                <div class="form-row">
                                    <div class="col-sm-12">
                                        <div class="form-group">
                                        <label for="job-name">Your Name <span class="errortext">*</span></label>
                                        <input id="job-name" type="text" class="form-control" v-model="paymentDetails.name" required>
                                        </div>
                                    </div>
                                    
                                </div>
                                
                                 <div class="form-row">
                                    <div class="col-sm-12">
                                        <div class="form-group">
                                        <label for="job-name">Enter your email address <span class="errortext">*</span></label>
                                        <input id="job-name" type="email" class="form-control" v-model="paymentDetails.email" required>
                                        </div>
                                    </div>
                                    
                                </div>
                                <div class="form-row">
                                  <div class="col-sm-12">
                                      <div class="form-group">
                                        <label for="job-name">Please enter the payments you are making</label>
                                        <table class="table">
                                        <tr>
                                          <th>&nbsp;</th>
                                          <th>Amount (GBP)</th>
                                          <th>Comments</th>
                                        </tr>
                                        <tr>
                                          <td>Rent</td>
                                          <td><input type="number" step="0.01" class="form-control form-control-sm" v-model="paymentDetails.rent_amount"/></td>
                                          <td><input type="text" class="form-control form-control-sm" v-model="paymentDetails.rent_comments"/></td>
                                        </tr>
                                        <tr>
                                          <td>Deposit</td>
                                          <td><input type="number" step="0.01" class="form-control form-control-sm" v-model="paymentDetails.deposit_amount"/></td>
                                          <td><input type="text" class="form-control form-control-sm" v-model="paymentDetails.deposit_comments"/></td>
                                        </tr>
                                        <tr>
                                          <td>Other</td>
                                          <td><input type="number" step="0.01" class="form-control form-control-sm" v-model="paymentDetails.other_amount"/></td>
                                          <td><input type="text" class="form-control form-control-sm" v-model="paymentDetails.other_comments"/></td>
                                        </tr>
                                        <tr>
                                          <td><strong>Total</strong></td>
                                          <td><input type="number" readonly class="form-control form-control-sm" v-model="total_amount" required/></td>
                                          <td><em>Payments should be made in GBP</em></td>
                                        </tr>
                                        </table>
                                      
                                      </div>
                                  </div>
                                  
                                </div>

                                 <div class="form-row">
                                    <div class="col-sm-12">
                                      <div class="form-group">
                                          <label for="job-name">Enter the dates of your stay</label>
                                            <table class="table">
                                          
                                            <tr>
                                              <td>Arrival Date</td>
                                              <td><input type="text" class="form-control form-control-sm" v-model="paymentDetails.arrival_date"/></td>
                                              
                                            </tr>
                                            <tr>
                                              <td>Departure Date</td>
                                              <td><input type="text" class="form-control form-control-sm" v-model="paymentDetails.departure_date"/></td>
                                              
                                            </tr>
                                          
                                            </table>
                                      
                                      </div>
                                  </div>
                                  
                                </div>
                                
                                <div class="form-group pt-4">
                                  <label for="job-message">Select the apartment you are making payment for</label>
                                  <select class="form-control"  v-model="paymentDetails.property">
                                      <option value="">Please Select</option>
                                      <option v-for="row in propertyList" :key="row" :value="row">{{ row }}</option>

                                  </select>
                                </div>
                                
                                <div class="form-group pt-4">
                                  <label for="job-message">Specify Any Special Requests (We will try to accomodate these) </label>
                                  <textarea class="form-control" id="job-message" rows="3" v-model="paymentDetails.special_requests"></textarea>
                                </div>

                               
                                
                                <div class="d-flex flex-column flex-md-row justify-content-between align-items-center">
                                    <button class="btn btn-primary" type="submit" >{{ buttonText }}</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
		</section>

    

        <Payment :paymentDetails="paymentDetails" :total_amount="total_amount" v-if="showPayment===1" v-on:showPaymentForm="showPayment=0" :stripeAPIToken="stripeAPIToken"  :page="page"></Payment>

      <section class="pb-0"  > 
          <div class="container">
              <div class="row section-title justify-content-center ">
                  <div class="col-md-11 col-lg-11 col-xl-11">
                    <h3 class="display-4">Reserving An Apartment</h3>
                    <ul>
                    <li>To secure a booking we ask you to pay a booking/ damage deposit</li>
                    <li>This deposit is fully refundable on your fulfilling the booking and on leaving the apartment in the same condition</li>
                    <li>Our apartments are located in a residential area and are unsuitable for parties, hen nights, etc. The full deposit will be forfeited if we receive complaints from residents re significant noise after 9pm or complaints from the Management Companies re unsociable behaviour.</li>
                    <li>The full rent should then be paid 3 weeks prior to arrival</li>
                    <li>Payments can be paid online or by posting a cheque to our office or online using Visa , Master Card, AMEX or PayPal using our secure payment options at www.mullanpayments.co.uk</li>
                    <li>Online payments incur a 3.5% booking charge (we are charged this so have to pass the cost on) - <span style="color:#cc0000">The deposit is not subject to this charge</span></li>
                    <li>Unfortunately we are unable to hold or reserve an apartment until the deposit is paid and we have sent you an email confirming the reservation</li>
                    </ul>
                  </div>
              </div>

              <div class="row section-title justify-content-center ">
                  <div class="col-md-11 col-lg-11 col-xl-11">
                    <h3 class="display-4">Refund Policy</h3>
                    <ul>
                    <li>The deposit paid serves as a booking/damage deposit and is fully refundable on your fulfilling your booking and following inspection by our rep on your leaving the apartment to confirm (i) there is no loss/ damage of property (ii) the apartment is in the same excellent condition it was received in (iii) We have not received complaints from Residents re significant noise after 9pm (iv) We have not received complaints from Management Companies re unsociable behaviour</li>
                    <li>Our apartments are located in a residential area and are unsuitable for parties, hen nights, etc. The full deposit will be forfeited if we receive complaints from management companies or residents re significant noise after 10pm.</li>
                    <li>In the event of a cancellation or no-show, the full rent paid will be forfeited and the deposit refunded</li>
                    </ul>
                  </div>
              </div>

              <div class="row section-title justify-content-center ">
                  <div class="col-md-11 col-lg-11 col-xl-11">
                    <h3 class="display-4">Contact Us For More Details</h3>
                    <ul>
                    <li>Please do not hesitate to contact us if you need additional information</li>
                    <li>Our office is open from 8.45 am until 17.30pm from Mon - Fri</li>
                    <li>We have a rep on call outside of normal office hours - our rep is on call <strong>until 11pm each night, 7 nights a week</strong> so if you have any queries, please do not hesitate to contact me by email or by phone</li>
                    <li>The out of office contact number is +44 (0)7964626258</li>
                    </ul>
                  </div>
              </div>
              
          </div>
          <div class="divider divider-bottom bg-primary-3 mt-5"></div>
		</section>

    </div>

</template>

<script>
import Payment from '@/views/Payment.vue';

let STRIPE_PK_PROPERTY = process.env.VUE_APP_STRIPE_PK_PROPERTY
let PROPERTY_LIST = process.env.VUE_APP_PROPERTY_LIST.split("|");

export default ({
  name: 'Property',
  components: {Payment},
  mounted () {
    
  },
   data() {
    return {
        showPayment:0,
       stripeAPIToken: STRIPE_PK_PROPERTY,
         paymentDetails: {
            type: 'Property',
            name: '',
            company_name: '',
            email: '',
            invoice_number: '',
            rent_amount: '',
            rent_comments: '',
            deposit_amount: '',
            deposit_comments: '',
            other_amount: '',
            other_comments: '',
            notes:'',
            work_info:'',
            property:'',
            arrival_date:'',
            departure_date:'',
            special_requests:''
         },
         buttonText: 'Proceed To Make Payment',
         propertyList: PROPERTY_LIST,
         page: 'property'
    };
  },
  computed: {
    // a computed getter
    total_amount: function () {
        var total = 0;

        if(parseFloat(this.paymentDetails.rent_amount) > 0)
        {
            total += parseFloat(this.paymentDetails.rent_amount) * 100;
        }
         if(parseFloat(this.paymentDetails.deposit_amount) > 0)
        {
            total += parseFloat(this.paymentDetails.deposit_amount)* 100;
        }
        if(parseFloat(this.paymentDetails.other_amount) > 0)
        {
            total += parseFloat(this.paymentDetails.other_amount)* 100;
        }
     return (total/100).toFixed(2);
    }
  },
  methods: {
      checkForm() {
        if(this.paymentDetails.name == null || this.paymentDetails.name == '')
        {
            alert("Please enter your name");
            return false;
        }
        if(this.paymentDetails.email == null || this.paymentDetails.email == '')
        {
            alert("Please enter your email address");
            return false;
        }
          if(this.total_amount == null || this.total_amount == '' || this.total_amount < 0.5 )
        {
            alert("Please enter an amount to pay");
            return false;
        }

         window.scrollTo(0,0);
        this.showPayment=1
          return true;
      }
  }
})
</script>
<template>
    <div>
      <section class=" bg-light" v-if="showPayment===0">
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-xl-8 col-lg-9 col-md-11">
                        <h2>Make a Mullan Training Payment Online</h2>
                        <div class="card card-body shadow mt-md-5" id="apply-form">
                            <form action="#" @submit.prevent="checkForm()">
                                <div class="form-row">
                                    <div class="col-sm-12">
                                        <div class="form-group">
                                        <label for="job-name">Your Name <span class="errortext">*</span></label>
                                        <input id="job-name" type="text" class="form-control" v-model="paymentDetails.name" required>
                                        </div>
                                    </div>
                                    
                                </div>
                                <div class="form-row">
                                    <div class="col-sm-12">
                                        <div class="form-group">
                                        <label for="job-name">Enter your company name <span class="errortext">*</span></label>
                                        <input id="job-name" type="text" class="form-control" v-model="paymentDetails.company_name" required>
                                        </div>
                                    </div>
                                    
                                </div>
                                 <div class="form-row">
                                    <div class="col-sm-12">
                                        <div class="form-group">
                                        <label for="job-name">Enter your email address <span class="errortext">*</span></label>
                                        <input id="job-name" type="email" class="form-control" v-model="paymentDetails.email" required>
                                        </div>
                                    </div>
                                    
                                </div>
                                <div class="form-row">
                                    <div class="col-sm-12">
                                        <div class="form-group">
                                        <label for="job-name">Invoice Number</label>
                                        <input id="job-name" type="text" class="form-control" v-model="paymentDetails.invoice_number">
                                        </div>
                                    </div>
                                    
                                </div>
                                 <div class="form-row">
                                    <div class="col-sm-12">
                                        <div class="form-group">
                                        <label for="job-name">Invoice Amount <span class="errortext">*</span></label>
                                        <input id="job-name" type="number" step="0.01" class="form-control" v-model="paymentDetails.invoice_amount" required>
                                        </div>
                                    </div>
                                    
                                </div>
                                <div class="form-row">
                                    <div class="col-sm-12">
                                        <div class="form-group">
                                        <label for="job-name">Other Amount</label>
                                        <input id="job-name" type="number" step="0.01" class="form-control" v-model="paymentDetails.other_amount">
                                        </div>
                                    </div>
                                    
                                </div>
                                <div class="form-row">
                                    <div class="col-sm-12">
                                        <div class="form-group">
                                        <label for="job-name">Total Payable</label>
                                        <input id="job-name" type="number" readonly class="form-control" v-model="total_amount" required>
                                        </div>
                                    </div>
                                    
                                </div>

                                <div class="form-group">
                                <label for="job-message">Additional Notes/Comments</label>
                                <textarea class="form-control"  rows="2" placeholder="" v-model="paymentDetails.notes"></textarea>
                                </div>

                                <div class="form-group">
                                <label for="job-message">If you do not know the invoice number please enter the course name and date so that we can allocate the payment to your account</label>
                                <textarea class="form-control"  rows="2" placeholder="" v-model="paymentDetails.course_info"></textarea>
                                </div>
                                
                                
                                <div class="d-flex flex-column flex-md-row justify-content-between align-items-center">
                                    <button class="btn btn-primary" type="submit" >{{ buttonText }}</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
		</section>

        <Payment :paymentDetails="paymentDetails" :total_amount="total_amount" v-if="showPayment===1" v-on:showPaymentForm="showPayment=0" :stripeAPIToken="stripeAPIToken" :page="page"></Payment>

    </div>

</template>

<script>
import Payment from '@/views/Payment.vue';

let STRIPE_PK_TRAINING = process.env.VUE_APP_STRIPE_PK_TRAINING;

export default ({
  name: 'Training',
  components: {Payment},
  mounted () {
    
  },
   data() {
    return {
        showPayment:0,
       stripeAPIToken: STRIPE_PK_TRAINING,
         paymentDetails: {
            type: 'Training',
            name: '',
            company_name: '',
            email: '',
            invoice_number: '',
            invoice_amount: '',
            other_amount: '',
            notes:'',
            course_info:''
         },
         buttonText: 'Proceed To Make Payment',
         page: 'training'
    };
  },
  computed: {
    // a computed getter
    total_amount: function () {
        var total = 0;

        if(parseFloat(this.paymentDetails.invoice_amount) > 0)
        {
            total += parseFloat(this.paymentDetails.invoice_amount) * 100;
        }
        if(parseFloat(this.paymentDetails.other_amount) > 0)
        {
            total += parseFloat(this.paymentDetails.other_amount) * 100;
        }
      return (total/100).toFixed(2);
    }
  },
  methods: {
      checkForm() {
        if(this.paymentDetails.name == null || this.paymentDetails.name == '')
        {
            alert("Please enter your name");
            return false;
        }
        if(this.paymentDetails.email == null || this.paymentDetails.email == '')
        {
            alert("Please enter your email address");
            return false;
        }
         if(this.total_amount == null || this.total_amount == '' || this.total_amount < 0.5 )
        {
            alert("Please enter an amount to pay");
            return false;
        }


         window.scrollTo(0,0);
         
        this.showPayment=1
          return true;
      }
  }
})
</script>